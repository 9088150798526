import day from "dayjs";
import { sift } from "radash";
import { Tabs } from "antd";
import { useLocalStorage } from "react-use";
import { useRef } from "react";

import { Activities } from "./activities";
import { AverageDealLengths } from "./average-deal-lengths";
import { OpportunityForecast } from "./opportunity-forecast/opportunity-forecast";
import { OpportunityFunnel } from "./opportunity-funnel";
import { ProductOpportunities } from "./product-opportunities";
import { Reports } from "./reports/reports";
import { Totals } from "./totals";
import { UserActivities } from "./user-activities";
import { WonOpportunities } from "./won-opportunities";
import { convertRange } from "./convert-range";
import { useActivityCounts } from "../../data/use-reports";
import { useFunnelOpportunityCounts } from "data/use-reports.js";
import { useHasSalesRole } from "../../hooks/use-has-role";
import { useNetworkingCounts } from "../../data/use-reports";
import { useOpportunityCountsBy } from "../../data/use-reports";
import { useProductOpportunityCountsBy } from "../../data/use-reports";
import { useUserActivityCounts } from "../../data/use-reports";
import { useUserOpportunityCountsBy } from "../../data/use-reports";

export const DashboardRoute = () => {
  const [tab, setTab] = useLocalStorage(
    "LAYOUT_STATE.DASHBOARD.TAB",
    "metrics"
  );

  const hasSalesRole = useHasSalesRole();
  const date = useRef(day());

  const loading = [
    useActivityCounts(),
    useActivityCounts(
      convertRange([date.current.subtract(1, "month"), date.current])
    ),
    useFunnelOpportunityCounts(),
    useNetworkingCounts(
      convertRange([date.current.subtract(1, "month"), date.current])
    ),
    useOpportunityCountsBy("stateId"),
    useProductOpportunityCountsBy("stateId"),
    useUserActivityCounts(
      convertRange([date.current.subtract(1, "month"), date.current])
    ),
    useUserOpportunityCountsBy("stateId"),
  ];

  if (sift(loading).length < loading.length) {
    return null;
  }

  return (
    <div
      className="sm:w-full 2xl:max-w-[2048px]"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "64px 1fr",
        height: "100%",
      }}
    >
      <Tabs
        activeKey={tab}
        onChange={setTab}
        tabBarStyle={{
          marginBottom: "0px",
          marginLeft: "24px",
        }}
        items={[
          {
            key: "metrics",
            label: "Metrics",
            children: null,
          },
          ...(hasSalesRole
            ? [
                {
                  key: "reports",
                  label: "Reports",
                  children: null,
                },
              ]
            : []),
        ]}
      />
      <div style={{ gridRowStart: 2, overflowY: "scroll" }}>
        {tab === "metrics" && (
          <div
            style={{
              overflowY: "scroll",
            }}
          >
            <div className="dashboard hidden xl:flex">
              <div className="mr-4 w-1/2 space-y-4">
                <OpportunityFunnel />
                <WonOpportunities />
                <ProductOpportunities />
              </div>
              <div className="w-1/2 space-y-4">
                <Totals />
                <Activities />
                <OpportunityForecast />
                <UserActivities />
                <AverageDealLengths />
              </div>
            </div>
            <div className="dashboard space-y-4 xl:hidden">
              <OpportunityFunnel />
              <Totals />
              <WonOpportunities />
              <Activities />
              <ProductOpportunities />
              <OpportunityForecast />
              <UserActivities />
              <AverageDealLengths />
            </div>
          </div>
        )}
        {tab === "reports" && <Reports />}
      </div>
    </div>
  );
};
