import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import map from "lodash/map";
import meanBy from "lodash/meanBy";
import reduce from "lodash/reduce";
import values from "lodash/values";
import { useState } from "react";
import { Fade } from "react-awesome-reveal";

import { styles } from "@evolved/constants";

import { Void } from "components/image-placeholders";

import { getDealLengthColor } from "utils/color/get-deal-length-color";
import { WidgetHeader } from "./widget-header";
import { useOpportunities } from "data/use-opportunities";
import { useUsers } from "data/use-users";
import { withSalesRole } from "../../components/with-role";
import { WidgetContainer } from "./widget-container";

const { CLICKABLE_STYLE } = styles;

const AccountsLink = ({ navigate }) => (
  <span onClick={() => navigate("/accounts")} style={CLICKABLE_STYLE}>
    accounts
  </span>
);

const OpportunitiesLink = ({ navigate }) => (
  <span onClick={() => navigate("/opportunities")} style={CLICKABLE_STYLE}>
    opportunities
  </span>
);

const ProductsLink = ({ navigate }) => (
  <span onClick={() => navigate("/products")} style={CLICKABLE_STYLE}>
    products
  </span>
);

const Component = () => {
  const [layout, setLayout] = useState("vertical");

  const opportunities = useOpportunities().all();
  const navigate = useNavigate();
  const users = useUsers();

  let data = reduce(
    opportunities,
    (acc, opportunity) => {
      if (isNil(get(opportunity, "timeline.totalDurationInDays"))) {
        return acc;
      }

      each(opportunity.users, (userId) => {
        const user = users.byId(userId);
        if (!user) return;

        acc[userId] = acc[userId] || {
          ...user,
          average: 0,
          opportunities: [],
        };

        acc[userId].opportunities.push(opportunity);
        acc[userId].average = meanBy(
          acc[userId].opportunities,
          "timeline.totalDurationInDays"
        );
      });

      return acc;
    },
    {}
  );

  data = values(data);
  data = map(data, (user) => ({ ...user, fill: getDealLengthColor(user._id) }));

  const axes =
    layout === "vertical"
      ? {
          xAxis: { type: "number" },
          yAxis: { dataKey: "alias", type: "category" },
        }
      : {
          xAxis: { dataKey: "alias" },
          yAxis: {},
        };

  return (
    <div>
      <div
        style={{ alignItems: "center", display: "flex", marginBottom: "24px" }}
      >
        <WidgetHeader label="Average Deal Length in Days" />
        <Button
          ghost
          onClick={() =>
            setLayout(layout === "vertical" ? "horizontal" : "vertical")
          }
          style={{ marginLeft: "auto" }}
          type="primary"
        >
          Toggle View
        </Button>
      </div>

      {isEmpty(data) ? (
        <Fade>
          <Void
            primary={
              "It seems you don't have any opportunities to average out the deal lengths with"
            }
            secondary={
              <>
                Go ahead and create some <ProductsLink navigate={navigate} />,{" "}
                <AccountsLink navigate={navigate} /> and{" "}
                <OpportunitiesLink navigate={navigate} />
              </>
            }
          />
        </Fade>
      ) : (
        <Fade>
          <ResponsiveContainer
            height={data.length > 4 ? data.length * 40 : 160}
          >
            <BarChart data={data} layout={layout}>
              <XAxis {...axes.xAxis} />
              <YAxis {...axes.yAxis} width={100} />
              <Tooltip
                formatter={(value, name) => {
                  if (name === "average") {
                    return [value.toFixed(1), "Days"];
                  }

                  return [value, name];
                }}
              />
              <Bar dataKey={"average"} maxBarSize={25} />
            </BarChart>
          </ResponsiveContainer>
        </Fade>
      )}
    </div>
  );
};

export const AverageDealLengths = withSalesRole(() => {
  return (
    <WidgetContainer>
      <Component />
    </WidgetContainer>
  );
});
