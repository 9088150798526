import CountUp from "react-countup";
import { Statistic } from "antd";
import {
  IdcardOutlined,
  RadarChartOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Fade } from "react-awesome-reveal";
import map from "lodash/map";
import sumBy from "lodash/sumBy";

import { getActivityColor } from "utils/color/get-activity-color";

import { RangeSelect } from "./range-select";
import { WidgetHeader } from "./widget-header";
import { WidgetContainer } from "./widget-container";
import { convertRange } from "./convert-range";

import {
  ORGANIZATION_ACTIVITIES,
  useDashboardDateFilters,
} from "./use-dashboard-filters";
import { useActivityCounts } from "../../data/use-reports";
import { useNetworkingCounts } from "../../data/use-reports";

const countFormatter = (value) => <CountUp end={value} separator="," />;

const Component = () => {
  const { widgetFilter, widgetFilterLabel, setWidgetFilter } =
    useDashboardDateFilters(ORGANIZATION_ACTIVITIES);

  const activityCounts = useActivityCounts(convertRange(widgetFilter));
  const networkingCounts = useNetworkingCounts(convertRange(widgetFilter));

  const {
    accountCount = 0,
    contactCount = 0,
    opportunityCount = 0,
  } = networkingCounts || {};

  const total = sumBy(activityCounts, "count");

  return (
    <Fade>
      <div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "24px",
          }}
        >
          <WidgetHeader count={total} label="Company Activity" />
          <RangeSelect
            tenses={["past", "all-time"]}
            {...{ widgetFilter, widgetFilterLabel, setWidgetFilter }}
          />
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <Statistic
            formatter={countFormatter}
            prefix={<RadarChartOutlined style={{ marginRight: "4px" }} />}
            title={"Accounts"}
            value={accountCount}
          />
          <Statistic
            formatter={countFormatter}
            prefix={<IdcardOutlined style={{ marginRight: "4px" }} />}
            title={"Contacts"}
            value={contactCount}
          />
          <Statistic
            formatter={countFormatter}
            prefix={<RocketOutlined style={{ marginRight: "4px" }} />}
            title={"Opportunities"}
            value={opportunityCount}
          />
        </div>
        <div
          style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
        >
          {map(activityCounts, (activityCount, i) => {
            const { _id, name, count } = activityCount;

            return (
              <Statistic
                key={_id}
                style={{
                  ...(i >= 3 ? { marginTop: "16px" } : {}),
                  width: "33.33%",
                }}
                title={name}
                value={count}
                valueStyle={{
                  borderLeft: `${getActivityColor(_id)} 6px solid`,
                  paddingLeft: "8px",
                }}
              />
            );
          })}
        </div>
      </div>
    </Fade>
  );
};

const Container = () => {
  return (
    <WidgetContainer>
      <Component />
    </WidgetContainer>
  );
};

export const Activities = Container;
