import { fieldTypes } from "@evolved/constants";

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

const { ACTIVITY_DATE, DATE, DOLLAR, FOLLOWUP_DATE, PERCENT, SET, TEXT } =
  fieldTypes;

export const account = {
  collection: "accounts",
  dataIndex: "accountId",
  idIndex: "accountId",
  isSortable: true,
  title: "Account",
  type: SET,
};

export const alias = {
  dataIndex: "alias",
  title: "Name",
  type: TEXT,
  mapUiUrl: (value) => `/opportunities/${value}`,
};

export const contacts = {
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: SET,
};

export const createdOn = {
  dataIndex: "createdOn",
  title: "Added On",
  type: DATE,
};

export const funnelProgress = {
  dataIndex: "funnelProgress",
  title: "Progress",
  type: PERCENT,
};

export const initialFunnelProgress = {
  dataIndex: "initialFunnelProgress",
  title: "Initial Funnel Progress",
  type: PERCENT,
};

export const initialState = {
  collection: "opportunityStates",
  dataIndex: "initialStateId",
  idIndex: "initialStateId",
  isSortable: true,
  title: "Initial Stage",
  type: SET,
};

export const lastActedOn = {
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: ACTIVITY_DATE,
};

export const lossReason = {
  collection: "lossReasons",
  dataIndex: "lossReasonId",
  title: "Loss Reason",
  isSortable: true,
  type: SET,
};

export const nextFollowup = {
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: FOLLOWUP_DATE,
};

export const partners = {
  collection: "vendors",
  dataIndex: "vendors",
  title: "Partners",
  type: SET,
};

export const products = {
  collection: "products",
  dataIndex: "products",
  title: "Products",
  type: SET,
  isSortable: true,
};

export const salesProcess = {
  collection: "salesProcesses",
  dataIndex: "salesProcessId",
  idIndex: "salesProcessId",
  isSortable: true,
  title: "Sales Process",
  type: SET,
};

export const startedOn = {
  dataIndex: "startedOn",
  title: "Started On",
  type: DATE,
};

export const state = {
  collection: "opportunityStates",
  dataIndex: "stateId",
  idIndex: "stateId",
  isSortable: true,
  title: "Stage",
  type: SET,
};

export const summary = {
  dataIndex: "summary",
  title: "Summary",
  type: TEXT,
};

export const tags = {
  collection: "tags",
  dataIndex: "tags",
  title: "Tags",
  type: SET,
};

export const targetWinDate = {
  dataIndex: "targetWinDate",
  title: "Target Win Date",
  type: DATE,
};

export const users = {
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: SET,
};

export const value = {
  dataIndex: "value",
  title: "Value",
  type: DOLLAR,
};

export const weightedRevenue = {
  dataIndex: "weightedRevenue",
  title: "Weighted Revenue",
  type: DOLLAR,
};

export const wonDate = {
  dataIndex: "wonDate",
  title: "Won Date",
  type: DATE,
};

export const COLLECTION = [
  account,
  alias,
  createdOn,
  contacts,
  funnelProgress,
  lastActedOn,
  lossReason,
  nextFollowup,
  partners,
  products,
  salesProcess,
  startedOn,
  state,
  summary,
  tags,
  targetWinDate,
  users,
  value,
  weightedRevenue,
  wonDate,
];

export const DEFAULT_FIELDS = [
  "stateId",
  "accountId",
  "contacts",
  "funnelProgress",
  "lastActedOn",
  "nextFollowupOn",
  "value",
];

export const FIXED_FIELDS = ["alias"];

export const MAP = reduceFieldsToMap(COLLECTION);
