import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Fade } from "react-awesome-reveal";
import { useLocalStorage } from "react-use";
import { useNavigate } from "react-router-dom";

import { styles } from "@evolved/constants";

import { formatDollars } from "../../utils/format-dollars";

import { Void } from "components/image-placeholders";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import {
  useProductOpportunityCountsBy,
  useUserOpportunityCountsBy,
} from "../../data/use-reports";
import { RangeSelect } from "./range-select";
import { WidgetHeader } from "./widget-header";
import { withSalesRole } from "../../components/with-role";
import { WidgetContainer } from "./widget-container";
import { useOrganization } from "../../data/use-organization";
import {
  PRODUCT_OPPORTUNITIES,
  useDashboardDateFilters,
} from "./use-dashboard-filters";
import { convertRange } from "./convert-range";

const { CLICKABLE_STYLE } = styles;

const AccountsLink = ({ navigate }) => (
  <span onClick={() => navigate("/accounts")} style={CLICKABLE_STYLE}>
    accounts
  </span>
);

const OpportunitiesLink = ({ navigate }) => (
  <span onClick={() => navigate("/opportunities")} style={CLICKABLE_STYLE}>
    opportunities
  </span>
);

const ProductsLink = ({ navigate }) => (
  <span onClick={() => navigate("/products")} style={CLICKABLE_STYLE}>
    products
  </span>
);

const Component = () => {
  const { widgetFilter, widgetFilterLabel, setWidgetFilter } =
    useDashboardDateFilters(PRODUCT_OPPORTUNITIES);

  const opportunityStates = useOpportunityStates().all();

  const productOpportunityCounts = useProductOpportunityCountsBy("stateId", convertRange(widgetFilter));
  const userOpportunityCounts = useUserOpportunityCountsBy("stateId", convertRange(widgetFilter));

  const navigate = useNavigate();
  const organization = useOrganization();

  const [isUser, setIsUser] = useLocalStorage(
    `${organization._id}.DASHBOARD.OPPORTUNITY_WIN_RATE.IS_USER`,
    true
  );

  const counts = isUser ? userOpportunityCounts : productOpportunityCounts;

  const wonState = opportunityStates.find(
    ({ systemKey }) => systemKey === "WON"
  );

  return (
    <div>
      <div
        style={{ alignItems: "center", display: "flex", marginBottom: "24px" }}
      >
        <WidgetHeader label="Opportunity Win Rate" />
        <RangeSelect
          tenses={["past", "all-time"]}
          {...{ widgetFilter, widgetFilterLabel, setWidgetFilter }}
        />
        <h4
          style={{
            ...CLICKABLE_STYLE,
            fontSize: "18px",
            marginBottom: "0px",
            marginLeft: "24px",
          }}
          onClick={() => setIsUser(!isUser)}
        >
          {isUser ? "By Sales Rep" : "By Product"}
        </h4>
      </div>

      {isEmpty(counts) ? (
        <Fade>
          <Void
            primary={"No opportunities yet."}
            secondary={
              <>
                Go ahead and create some <ProductsLink navigate={navigate} />,{" "}
                <AccountsLink navigate={navigate} /> and{" "}
                <OpportunitiesLink navigate={navigate} />
              </>
            }
          />
        </Fade>
      ) : (
        <Fade>
          <div
            style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
          >
            {map(counts, ({ _id, name, counts }, i) => {
              const {
                count: wonCount,
                value: wonValue,
              } = (
                  find(
                    counts,
                    ({ stateId }) => Number(stateId) === Number(wonState._id)
                  ) || { count: 0, value: 0 }
                );

              const { count, value } = counts.reduce((acc, { stateId, count, value }) => {
                if (organization.settings?.winRateOpportunityStateIds) {
                  const opportunityState = opportunityStates.find(({ _id }) => _id === stateId);

                  if (["WON", "LOST"].includes(opportunityState?.systemKey)) {
                    return {
                      count: acc.count + count,
                      value: acc.value + value,
                    };
                  }

                  if (organization.settings?.winRateOpportunityStateIds.includes(stateId)) {
                    return {
                      count: acc.count + count,
                      value: acc.value + value,
                    };
                  }

                  return acc;
                }

                return {
                  count: acc.count + count,
                  value: acc.value + value,
                };
              }, { value: 0, count: 0 });

              const closedRate =
                count === 0 ? 0 : Math.round((wonCount / count) * 100);

              return (
                <div
                  key={_id}
                  style={{
                    ...(i >= 3 ? { marginTop: "16px" } : {}),
                    width: "33%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {name}
                  </div>
                  <div >
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div
                        style={{
                          fontSize: "32px",
                          fontWeight: "600",
                          color: "#7F5196",
                          lineHeight: "40px",
                        }}
                      >
                        {closedRate}
                        <span
                          style={{
                            fontSize: "18px",
                            lineHeight: "32px",
                          }}
                        >
                          %
                        </span>
                      </div>
                      <div
                        className="text-sm font-light"
                        style={{
                          fontWeight: "600",
                          lineHeight: 1,
                          marginLeft: "12px"
                        }}
                      >
                        {wonCount} / {count}
                      </div>
                    </div>
                    <div
                      className="text-lg mt-1 text-grey-500"
                      style={{
                        lineHeight: 1,
                      }}
                    >
                      {formatDollars(wonValue)} / {formatDollars(value)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Fade>
      )}
    </div>
  );
};

export const ProductOpportunities = withSalesRole(() => {
  return (
    <WidgetContainer>
      <Component />
    </WidgetContainer>
  );
});
