import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import day from "dayjs";
import { useLocalStorage } from "react-use";

import { dateFilters } from "@evolved/constants";

import { useOrganization } from "../../data/use-organization";

export const ONE_MONTH = "ONE_MONTH";
export const TWO_MONTHS = "TWO_MONTHS";
export const SIX_MONTHS = "SIX_MONTHS";
export const CALENDAR_YEAR = "CALENDAR_YEAR";

export const COMPANY_TOTALS = "COMPANY_TOTALS";
export const ORGANIZATION_WINS = "ORGANIZATION_WINS";
export const ORGANIZATION_ACTIVITIES = "ORGANIZATION_ACTIVITIES";
export const USER_ACTIVITIES = "USER_ACTIVITIES";
export const PRODUCT_OPPORTUNITIES = "PRODUCT_OPPORTUNITIES";

const {
  FUTURE_15_DAYS,
  FUTURE_1_MONTH,
  FUTURE_3_MONTHS,
  FUTURE_6_MONTHS,
  FUTURE_1_YEAR,
} = dateFilters;

const ranges = {
  [ONE_MONTH]: () => [day().subtract(1, "month"), day()],
  [TWO_MONTHS]: () => [day().subtract(2, "month"), day()],
  [SIX_MONTHS]: () => [day().subtract(6, "month"), day()],
  [CALENDAR_YEAR]: () => [
    day().dayOfYear(1).hour(0).minute(0).second(0).millisecond(0),
    day(),
  ],
  [FUTURE_15_DAYS]: () => [day(), day().add(15, "days")],
  [FUTURE_1_MONTH]: () => [day(), day().add(1, "month")],
  [FUTURE_3_MONTHS]: () => [day(), day().add(3, "month")],
  [FUTURE_6_MONTHS]: () => [day(), day().add(6, "month")],
  [FUTURE_1_YEAR]: () => [day(), day().add(1, "year")],
};

const FORMATTED_DATES = {
  [ONE_MONTH]: "Last Month",
  [TWO_MONTHS]: "Last 2 Months",
  [SIX_MONTHS]: "Last 6 Months",
  [CALENDAR_YEAR]: "Calendar Year",
  [FUTURE_15_DAYS]: "Next 15 Days",
  [FUTURE_1_MONTH]: "Next Month",
  [FUTURE_3_MONTHS]: "Next 3 Months",
  [FUTURE_6_MONTHS]: "Next 6 Months",
  [FUTURE_1_YEAR]: "Next Year",
};

const getFormattedDate = (range) => {
  if (isString(range)) return FORMATTED_DATES[range];
  if (isEmpty(range)) return "All Time";

  // todo: update to utils/format-date once that is migrated properly to dayjs
  return `${day(range[0]).format("MMM DD YYYY")} - ${day(range[1]).format(
    "MMM DD YYYY"
  )}`;
};

export const getTimeRange = (range) => {
  if (isEmpty(range)) return range;
  if (isArray(range)) {
    return [day(range[0]), day(range[1])];
  }

  return (ranges[range] || ranges[ONE_MONTH])();
};

export const useDashboardDateFilters = (widget) => {
  const organization = useOrganization();

  const [state, setState] = useLocalStorage(
    `${organization._id}.LAYOUT_STATE.DASHBOARD.DATE_FILTERS`,
    {
      [ORGANIZATION_ACTIVITIES]: ONE_MONTH,
      [ORGANIZATION_WINS]: [],
      [USER_ACTIVITIES]: ONE_MONTH,
      [PRODUCT_OPPORTUNITIES]: [],
    }
  );

  return {
    widgetFilter: getTimeRange(state[widget]),
    widgetFilterLabel: getFormattedDate(state[widget]),
    widgetFilterState: state[widget],
    setWidgetFilter: (filter) =>
      setState({
        ...state,
        [widget]: filter,
      }),
  };
};
