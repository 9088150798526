import { Select } from "antd";
import { useLocalStorage } from "react-use";
import { CalendarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import moment from "moment";

import { styles, viewTypes } from "@evolved/constants";
import { opportunityFields } from "@evolved/views";

import { Filters } from "./filters";
import { EmptyBox } from "components/image-placeholders";
import { WidgetHeader } from "../widget-header";
import { WidgetContainer } from "../widget-container";
import { formatDollars } from "utils/format-dollars";
import { withSalesRole } from "../../../components/with-role";

import { useOpportunities } from "../../../data/use-opportunities";
import { useOpportunityStates } from "../../../data/use-opportunity-states";
import { useOrganization } from "../../../data/use-organization";
import { useViewStore } from "../../../stores/view";

const { CLICKABLE_STYLE } = styles;
const { OPPORTUNITY } = viewTypes;

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const OpportunitiesLink = () => {
  const navigate = useNavigate();

  return (
    <span onClick={() => navigate("/opportunities")} style={CLICKABLE_STYLE}>
      opportunities
    </span>
  );
};

const Component = () => {
  const navigate = useNavigate();

  const viewOpportunities = (dateRange, opportunityStates) => {
    useViewStore.getState().setView(OPPORTUNITY)({
      fields: useViewStore.getState()[OPPORTUNITY].fields,
      filters: [
        {
          dataIndex: opportunityFields.targetWinDate.dataIndex,
          value: dateRange,
        },
        {
          dataIndex: opportunityFields.state.dataIndex,
          value: opportunityStates,
        },
      ],
      preset: true,
    });

    navigate("/opportunities");
  };

  const organization = useOrganization();
  const opportunityStates = useOpportunityStates();
  const opportunities = useOpportunities().all({ includeArchived: false });

  const customDateFields = organization.userDefinedFields.filter(
    ({ dataType, type }) => dataType === "DATE" && type === "OPPORTUNITY"
  );

  const potentialOpenStates = opportunityStates
    .all({ includeArchived: false })
    .filter(({ systemKey }) => systemKey !== "WON" && systemKey !== "LOST");

  const [legacyOpenStates, setOpenStates] = useLocalStorage(
    `${organization._id}.LAYOUT_STATE.DASHBOARD.OPPORTUNITY_FORECAST_OPEN_STATES`,
    potentialOpenStates.map(({ _id }) => _id)
  );

  const openStates = legacyOpenStates
    .filter((id) => opportunityStates.byId(id, { includeArchived: false }))
    .map((id) => Number(id));

  const [rangeFilter, setRangeFilter] = useLocalStorage(
    `${organization._id}.LAYOUT_STATE.DASHBOARD.OPPORTUNITY_FORECAST_RANGE_FILTER`
  );

  let rangeFilterLabel = "";

  if (rangeFilter) {
    const [, year, month] = rangeFilter.split("_");

    rangeFilterLabel = `${month}, ${year}`;
  }

  const [filterKey, setFilterKey] = useLocalStorage(
    `${organization._id}.LAYOUT_STATE.DASHBOARD.OPPORTUNITY_FORECAST_FILTER_KEY`,
    "targetWinDate"
  );

  const allStates = opportunityStates
    .all()
    .filter(
      ({ _id, systemKey }) => openStates.includes(_id) || systemKey === "WON"
    );

  const inProgressStates = allStates.filter(
    ({ systemKey }) => systemKey !== "WON"
  );
  const wonStates = allStates.filter(({ systemKey }) => systemKey === "WON");

  const [, year, month] = rangeFilter?.split("_") || [];

  const filteredOpportunities = rangeFilter
    ? opportunities.filter((opportunity) => {
        const value = get(opportunity, filterKey);

        return (
          value &&
          moment
            .unix(value)
            .isBetween(
              moment().year(year).month(month).startOf("month"),
              moment().year(year).month(month).endOf("month")
            ) &&
          allStates.map(({ _id }) => _id).includes(opportunity.stateId)
        );
      })
    : [];

  const alreadyWon = filteredOpportunities.filter(
    (opportunity) =>
      opportunityStates.byId(opportunity.stateId)?.systemKey === "WON"
  );

  const inProgress = filteredOpportunities.filter(
    (opportunity) =>
      opportunityStates.byId(opportunity.stateId)?.systemKey !== "WON"
  );

  if (isEmpty(opportunities)) {
    return (
      <Fade>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "12px",
            }}
          >
            <WidgetHeader label="Opportunity Value Forecast" />
          </div>
          <EmptyBox
            primary={"You do not yet have any opportunities."}
            secondary={
              <>
                Head over to your <OpportunitiesLink /> and log some
              </>
            }
          />
        </div>
      </Fade>
    );
  }

  let body;

  if (!rangeFilter) {
    body = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          fontWeight: 600,
          marginBottom: "24px",
        }}
      >
        Select a date range filter to see your opportunity forecast.{" "}
        <span style={{ fontSize: "24px", marginRight: "8px" }}>🚀</span>
      </div>
    );
  } else {
    body = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          marginBottom: "24px",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={() =>
            viewOpportunities(
              rangeFilter,
              allStates.map(({ _id }) => _id)
            )
          }
          style={{
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Total
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#7F5196",
              lineHeight: "40px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                lineHeight: "32px",
              }}
            >
              $
            </span>
            {formatDollars(sumBy(filteredOpportunities, "value"), {
              noSign: true,
            })}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: 1,
              color: "#8C8C8C",
            }}
          >
            From {filteredOpportunities.length}
          </div>
        </div>
        <div
          onClick={() =>
            viewOpportunities(
              rangeFilter,
              wonStates.map(({ _id }) => _id)
            )
          }
          style={{
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Already Won
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#7F5196",
              lineHeight: "40px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                lineHeight: "32px",
              }}
            >
              $
            </span>
            {formatDollars(sumBy(alreadyWon, "value"), { noSign: true })}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: 1,
              color: "#8C8C8C",
            }}
          >
            From {alreadyWon.length}
          </div>
        </div>
        <div
          onClick={() =>
            viewOpportunities(
              rangeFilter,
              inProgressStates.map(({ _id }) => _id)
            )
          }
          style={{
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
            }}
          >
            In Progress
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#7F5196",
              lineHeight: "40px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                lineHeight: "32px",
              }}
            >
              $
            </span>
            {formatDollars(sumBy(inProgress, "value"), { noSign: true })}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: 1,
              color: "#8C8C8C",
            }}
          >
            From {inProgress.length}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fade>
      <div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "12px",
          }}
        >
          <WidgetHeader label="Opportunity Value Forecast" />
          <h4
            style={{
              marginBottom: "0px",
              marginRight: "8px",
              fontSize: "18px",
            }}
          >
            {rangeFilterLabel}
          </h4>
          <CalendarOutlined style={{ fontSize: "18px" }} />
        </div>
        <div style={{ display: "flex", marginBottom: "12px" }}>
          <div style={{ fontWeight: 600, width: "75px", marginRight: "8px" }}>
            At Stage
          </div>
          <div style={{ flexGrow: 1 }}>
            <Select
              filterOption={filterOption}
              mode="multiple"
              notFoundContent={"No match"}
              optionFilterProp="children"
              showSearch
              options={potentialOpenStates.map((option) => ({
                value: option._id,
                label: option.label,
              }))}
              value={openStates}
              onChange={setOpenStates}
              size="small"
              style={{ width: "100%" }}
            />
            <div style={{ marginLeft: "4px" }}>*Won is always included</div>
          </div>
        </div>
        <Filters
          customDateFields={customDateFields}
          setFilterKey={setFilterKey}
          filterKey={filterKey}
          setRangeFilter={setRangeFilter}
          rangeFilter={rangeFilter}
        />
        {body}
      </div>
    </Fade>
  );
};

const Container = () => {
  return (
    <WidgetContainer>
      <Component />
    </WidgetContainer>
  );
};

export const OpportunityForecast = withSalesRole(Container);
